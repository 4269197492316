<template>
  <div class="content-wrapper help-center">
    <h1 class="mb-5">Help center</h1>
    <b-card class="mb-4">
      <b-row class="my-1">
        <b-col lg="3">
          <b-input-group size="sm" class="mb-2">
            <b-input-group-prepend is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Search"
            ></b-form-input>
          </b-input-group>
        </b-col>
        <b-col lg="3">
          <b-button
            size="sm"
            class="mb-2"
            v-b-toggle.collapse-advancedFilter
            :disabled="advancedFilter_visible"
          >
            <b-icon icon="filter" aria-hidden="true"></b-icon> Advanced Filter
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-collapse
      id="collapse-advancedFilter"
      v-model="advancedFilter_visible"
      class="mt-2"
    >
      <b-card class="mb-4">
        <div class="d-flex">
          <span>Advanced Filter</span>
          <b-button-close v-b-toggle.collapse-advancedFilter class="ml-auto" />
        </div>
        <b-row class="mt-3">
          <b-col>
            <b-form-select
              v-model="typeListSelected"
              :options="typeList"
            ></b-form-select>
          </b-col>
          <b-col>
            <b-form-select
              v-model="statusListSelected"
              :options="statusList"
            ></b-form-select>
          </b-col>
        </b-row>
      </b-card>
    </b-collapse>
    <b-card>
      <b-table
        hover
        :items="helpCenterList"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :filter="filter"
      >
        <template #cell(data)="row">
          {{ formatDate(row.value) }}
        </template>
        <template #cell(status_text)="row">
          <b-icon-circle-fill
            class="mr-1"
            :class="{
              'text-success': row.item.id_status == 2,
              'text-warning': row.item.id_status == 3,
              'text-danger': row.item.id_status == 1,
            }"
          />
          {{ row.value }}
        </template>
        <template #cell(need_reply)="row">
          <b-icon-chat-fill
            class="ml-4"
            :class="{ not_reply: !row.item.need_reply }"
          />
        </template>
        <template #cell(actions)="row">
          <b-icon-pencil-fill
            v-b-toggle.sidebar-chat
            @click="currentEntry = row.item"
            class="ml-3 pointer text-primary"
          ></b-icon-pencil-fill>
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="fill"
        size="sm"
        class="mt-4 mb-1"
      ></b-pagination>
    </b-card>
    <b-sidebar
      class="right-sidebar"
      id="sidebar-chat"
      right
      shadow
      lazy
      @hidden="resetSidebar"
    >
      <Chat
        :item="currentEntry"
        @update-reply="updateReply"
        @close-ticket="closeTicket"
      />
    </b-sidebar>
  </div>
</template>

<script>
import Chat from "../components/Chat.vue";
import AxiosService from "../axiosServices/AxiosService";
import { datetimeToString } from "@/utils/DatetimeUtils.js";
export default {
  components: { Chat },
  data() {
    return {
      items: [],
      fields: [
        { key: "data", label: "Date" },
        { key: "type_text", label: "Type" },
        { key: "titolo", label: "Title" },
        { key: "created_by_string", label: "Created by" },
        { key: "status_text", label: "Status" },
        { key: "need_reply", label: "Need reply" },
        { key: "actions", label: "Action" },
      ],
      currentPage: 1,
      perPage: 10,
      filter: "",
      advancedFilter_visible: false,
      currentEntry: null,

      typeList: [{ value: null, text: "All types " }],
      typeListSelected: null,

      statusList: [{ value: null, text: "All status " }],
      statusListSelected: null,
    };
  },
  service: null,
  created() {
    this.service = new AxiosService("HelpDesk");
  },
  mounted() {
    this.loadList();
    this.timer = setInterval(this.loadList, 30000);
    this.service.getOptions("HelpDeskType").then((data) => {
      this.typeList = this.typeList.concat(data);
    });
    this.service.getOptions("HelpDeskStatus").then((data) => {
      this.statusList = this.statusList.concat(data);
    });
  },
  methods: {
    loadList() {
      if (this.currentEntry === null) {
        this.service.read().then((data) => {
          this.items = data;
        });
      }
    },
    resetSidebar() {
      this.currentEntry = null;
      this.loadList();
    },
    updateReply() {
      if (this.currentEntry) {
        this.currentEntry["need_reply"] = false;
      }
    },
    formatDate(datetime) {
      return datetimeToString(datetime);
    },
    closeTicket() {
      if (this.currentEntry) {
        const item = JSON.parse(JSON.stringify(this.currentEntry));
        item.id_status = 2;
        if (item.id) {
          this.service
            .update(item)
            .then((response) => {
              if (response.id == item.id) {
                this.currentEntry.id_status = 2;
                this.currentEntry.status_text = "Closed";
                this.$root.$emit("bv::toggle::collapse", "sidebar-chat");
                this.$successToast("Ticket closed");
              } else {
                this.$errorToast();
                return false;
              }
            })
            .catch(() => {
              this.$errorToast();
              return false;
            });
        }
      }
    },
  },
  computed: {
    totalRows() {
      return this.helpCenterList ? this.helpCenterList.length : 0;
    },
    helpCenterList() {
      return this.items.filter((item) => {
        if (this.typeListSelected && item.id_type != this.typeListSelected)
          return false;
        if (
          this.statusListSelected &&
          item.id_status != this.statusListSelected
        )
          return false;
        return true;
      });
    },
  },
  watch: {
    filter: {
      handler() {
        this.currentPage = 1;
      },
    },
  },
};
</script>